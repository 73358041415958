import { CallAPIFunction } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";
import { ICreateCacheJobRequest } from "../../../models/Cache/ICreateCacheJobRequest";
import { IDriverTimeCheckInfo } from "../../../models/Drivers/IDriverTimeCheckInfo";

// Cache Jobs
export async function createCacheJob(
  callAPI: CallAPIFunction,
  job: ICreateCacheJobRequest,
) {
  const { status } = await callAPI(
    RequestType.Post,
    "/cacheJobs",
    "Could not create the cache job",
    job,
  );

  return status === 200;
}

export async function getCacheJob(callAPI: CallAPIFunction, jobId: string) {
  const { payload, status } = await callAPI(
    RequestType.Get,
    `/cacheJobs/${jobId}`,
    "Failed to retrieve cache job details.",
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function getTimeCheckColumnOptions(
  callAPI: CallAPIFunction,
  driverName: string,
  connectionId: string,
) {
  const { status, payload } = await callAPI(
    RequestType.Get,
    `/drivers/${driverName}/${connectionId}/timeCheckColumns`,
    "An error occurred loading the job information",
  );
  if (status === 200) {
    return payload as IDriverTimeCheckInfo;
  } else {
    return null;
  }
}

export async function getTableColumns(
  callAPI: CallAPIFunction,
  connectionName: string,
  schemaName: string,
  tableName: string,
) {
  const searchParams = new URLSearchParams({
    catalogName: connectionName,
    schemaName: schemaName,
    tableName: tableName,
  });
  const columnURL = `/columns?${searchParams}`;
  const { status, payload } = await callAPI(RequestType.Get, columnURL, "");
  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function refreshCacheJob(callAPI: CallAPIFunction, jobId: string) {
  const { status } = await callAPI(
    RequestType.Put,
    `/cacheJobs/refresh/${jobId}`,
    "Failed to refresh cache for job.",
  );

  return status === 200;
}

export async function updateCacheJobs(
  callAPI: CallAPIFunction,
  requestModel: ICreateCacheJobRequest,
) {
  const { status } = await callAPI(
    RequestType.Put,
    "/cacheJobs/jobs/update",
    "Could not update the cache job",
    requestModel,
  );

  return status === 200;
}
