import { useState, useEffect, useContext } from "react";
import { RequestType } from "../../../../../components/withAPI";
import { SelectSchemaWizardContext } from "../SelectSchemaWizardContext";
import { IStepBodyProps } from "../../../../../components/wizard/components/StepWizardStep";
import { useAPI } from "../../../../../components/useAPI";
import {
  ToastrError,
  ToastrSuccess,
} from "../../../../../services/toastrService";
import Loader from "../../../../../components/Loader";
import { useParams } from "react-router-dom";
import { sortAssetsByAlias } from "../../ObjectSorter";
import { AddAssetWizardContext as addAssetWizardContext1 } from "../AddAssetWizardContext";
import { SchemaTable } from "../SchemaTable";
import { IDatasetBulkAddError } from "../DatasetBulkAddErrorAccordion";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { StepWizardStepFooter } from "../../../../../components/wizard/components/StepWizardStepFooter";
import { StepWizardContext } from "../../../../../components/wizard/components/StepWizardContext";
import _ from "lodash";
import { IWorkspaceAssetListItemsBulkCreate } from "../../../../../bffmodels/IWorkspaceAssetListItemsBulkCreate";
import { NotificationBar } from "../../../../../components/notification/NotificationBar";
import { AssetType } from "src/models";
import { CDataTypography } from "src/components/text/CDataTypography";
import { Spinner } from "reactstrap";
import { DataAssetCategory } from "src/models/Datasets/DataAssetCategory";

export type Schema = {
  schemaName: string;
  parentName?: string;
  subRows: Schema[];
  isRowDisabled: boolean;
  assetType?: AssetType;
  dataAssetCategory?: DataAssetCategory;
  isExpanded: boolean;
};

interface ISelectSchemaTableProps extends IStepBodyProps {
  helperText?: string;
  isCacheJobWizard: boolean;
  nextStepName?: string;
  previousStepName: string;
  setAddAssertErrors: ((errors: IDatasetBulkAddError[]) => void) | null;
}

export const SelectSchemaTable = (props: ISelectSchemaTableProps) => {
  const { helperText, setAddAssertErrors } = props;

  const {
    completedSteps,
    setModalSize,
    setPendingCompletedStepsToCurrentStep,
    setStepCompleted,
    resetPendingCompletedSteps,
    toggleModal,
  } = useContext(StepWizardContext);
  const { assetList, selectedConnection, setAssetList } = useContext(
    SelectSchemaWizardContext,
  );
  const addAssetWizardContext = useContext(addAssetWizardContext1);

  const [data, setData] = useState<Schema[]>([]);
  const [selectedTables, setSelectedTables] = useState<Schema[]>([]);

  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showDataChangeAlert, setShowDataChangeAlert] = useState(false);

  // True while we're saving the selected derived views, disables the Confirm button to prevent
  // duplicate submission which will add the same derived views multiple times.
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const api = useAPI();
  const workspaceId = useParams().workspaceId;

  useEffect(() => {
    async function getInitialData() {
      const connectionName = selectedConnection.name;
      const schemaList: string[] = await fetchSchemas(connectionName);

      const initialData: Schema[] = [];
      schemaList?.forEach((currentSchema) => {
        initialData.push({
          schemaName: currentSchema,
          subRows: [],
          isRowDisabled: false,
          isExpanded: schemaList.length === 1,
        });
      });

      setData(initialData);
      setLoading(false);
    }

    getInitialData();
    setModalSize("md");
  }, []); // eslint-disable-line

  async function fetchSchemas(catalog: string) {
    if (!catalog) return;
    try {
      setDisableConfirmButton(true);

      const { status, payload } = await api.callAPI(
        RequestType.Get,
        `/schemas?catalogName=${encodeURIComponent(catalog)}`,
        "Failed to get user defined connection list due to the following error:",
      );

      if (status === 200) {
        if (payload.error) {
          ToastrError("Error fetching results", payload.error.message);
          return;
        }

        const schemas = payload.results[0].rows;
        const schemaArray = schemas.map((schema: any) => {
          return schema[1];
        });

        return schemaArray;
      }
    } catch (err) {
      ToastrError("Error fetching results", err);
    } finally {
      setDisableConfirmButton(false);
    }
  }

  async function createAssetFromConnection() {
    setIsProcessing(true);
    if (props.nextStepName) {
      setAssetList(
        selectedTables.map((table) => ({
          id: `${table.schemaName}.${table.parentName}`,
          assetType: AssetType.Data,
          // Note that the items below look wrong, but this is mapped correctly.
          sourceSchema: table.parentName,
          sourceTable: table.schemaName,
        })),
      );

      setStepCompleted(props.currentStep!);
      setShowDataChangeAlert(false);

      props.goToNamedStep!(props.nextStepName);
      return;
    }

    const connectionId = selectedConnection.id;
    const folderId = window.location.href.includes("folder")
      ? window.location.pathname.split("/").pop()
      : null;

    const body = {
      Records: selectedTables.map((table) => {
        return {
          ConnectionId: connectionId,
          ParentId: folderId,
          SchemaName: table.parentName,
          TableName: table.schemaName,
          AssetType: table.assetType,
          DataAssetCategory: table.dataAssetCategory,
        };
      }),
    };

    setAddAssertErrors?.([]);
    const { status, payload } =
      await api.callAPI<IWorkspaceAssetListItemsBulkCreate>(
        RequestType.Post,
        `/workspaces/${workspaceId}/assets/fromConnection/batch`,
        "Error creating folder:",
        body,
      );
    if (status === 200) {
      setAssetList((prevAssetList) => {
        const newAssetList = [...(prevAssetList ?? [])];
        newAssetList.push(...payload!.createdDataAssets);

        sortAssetsByAlias(newAssetList);
        return newAssetList;
      });
      if (payload!.createdDataAssets.length > 0) {
        ToastrSuccess(
          "Asset successfully added",
          `Successfully added asset(s) to ${addAssetWizardContext.containerName}`,
        );
      }

      if (payload!.errors.length > 0) {
        if (setAddAssertErrors != null) {
          setAddAssertErrors(payload!.errors);
        }
      }
    }
    setIsProcessing(false);
    toggleModal();
  }

  // If the step is being revisited and the user selects different schemas, block the user from jumping forward and show the alert banner.
  function checkIfTablesHaveChanged(newTableSelection: Schema[]) {
    if (
      completedSteps.includes(props.currentStep!) &&
      !_.isEqual(newTableSelection, assetList)
    ) {
      setShowDataChangeAlert(true);
      setPendingCompletedStepsToCurrentStep(props.currentStep!);
    } else {
      resetPendingCompletedSteps();
      setShowDataChangeAlert(false);
    }
  }

  const dataChangeAlert =
    props.isCacheJobWizard && showDataChangeAlert ? (
      <div className="mt-3 mb-2">
        <NotificationBar
          barColor={"notification-bar-blue"}
          message={`You have made changes that require you to configure your jobs again in the next step.`}
        />
      </div>
    ) : null;

  function renderSelectSchemaTable() {
    return (
      <div className="select-schema-table">
        {helperText && (
          <CDataTypography className="schemaHelperText">
            {helperText}
          </CDataTypography>
        )}

        {dataChangeAlert}
        <SchemaTable
          data={data}
          setData={setData}
          setSelectedTables={setSelectedTables}
          checkIfTablesHaveChanged={checkIfTablesHaveChanged}
        />
        <StepWizardStepFooter>
          <CDataButton
            buttonType={ButtonType.Borderless}
            onClick={() => props.goToNamedStep!(props.previousStepName)}
          >
            <span className="btn-outline-primary">Back</span>
          </CDataButton>
          <CDataButton
            buttonType={ButtonType.Primary}
            onClick={createAssetFromConnection}
            disabled={selectedTables.length === 0 || disableConfirmButton}
            data-testid={props.nextStepName ? undefined : "button-confirm"}
          >
            {props.nextStepName ? "Next" : "Confirm"}
          </CDataButton>
        </StepWizardStepFooter>
        <div hidden={!isProcessing}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
      </div>
    );
  }

  const contents = loading ? <Loader /> : renderSelectSchemaTable();

  return contents;
};
