import {
  useState,
  useEffect,
  useMemo,
  useRef,
  SetStateAction,
  Dispatch,
  useContext,
} from "react";

import { Schema } from "./Steps/SelectSchemaTable";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getTablesForSchema } from "src/api/metadata/getTablesForSchema";
import { CDataCheckbox } from "src/components/CDataCheckbox";
import { Backdrop, CircularProgress, Portal, ButtonBase } from "@mui/material";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { SearchInput } from "src/components/tables/components/SearchInput";
import { TextWithOverflowTooltip } from "src/components/text/TextWithOverflowTooltip";
import { CDChip } from "@cdatacloud/component-library";
import { CDataTooltip, CDataTooltipType } from "src/components/CDataTooltip";
import { DataAssetCategory } from "src/models/Datasets/DataAssetCategory";
import { AssetType } from "src/models";
import { SelectSchemaWizardContext } from "./SelectSchemaWizardContext";
import { listCustomReports } from "src/pages/dataExplorer/api/listCustomReports";

interface ISchemaTableProps {
  data: Schema[];
  setData: Dispatch<SetStateAction<Schema[]>>;
  setSelectedTables: (tables: Schema[]) => void;
  checkIfTablesHaveChanged?: (newTableSelection: Schema[]) => void;
}

export function SchemaTable(props: ISchemaTableProps) {
  const { data, setData, checkIfTablesHaveChanged } = props;
  const { existingCacheJobs, selectedConnection } = useContext(
    SelectSchemaWizardContext,
  );
  const [inputFilterValue, setInputFilterValue] = useState("");

  function onChangeFilter(value: string) {
    setInputFilterValue(value);
  }

  // This is a flat list of all schemas and tables combined into one big array.
  // This is necessary for the virtualizer to work.
  // We conditionally render only the visible schemas and/or tables.
  const displayedSchemasAndTables = useMemo(() => {
    return data.flatMap((schema) => {
      const visibleRows = [schema];

      if (schema.isExpanded) {
        visibleRows.push(...schema.subRows);
      }

      return visibleRows;
    });
  }, [data]);

  const filteredSchemasAndTables = useMemo(() => {
    // If we aren't filtering, just return the regular list of schemas and tables.
    if (isEmpty(inputFilterValue.trim())) {
      return displayedSchemasAndTables;
    }

    const displayedSchemas = data
      .filter(
        (schema) =>
          schema.schemaName
            .toLowerCase()
            .includes(inputFilterValue.toLowerCase().trim()) ||
          schema.subRows.some((r) =>
            r.schemaName
              .toLowerCase()
              .includes(inputFilterValue.toLowerCase().trim()),
          ),
      )
      .map((schema) => {
        return {
          ...schema,
          isExpanded: schema.subRows.some((r) =>
            r.schemaName
              .toLowerCase()
              .includes(inputFilterValue.toLowerCase().trim()),
          ),
        };
      });

    return displayedSchemas.flatMap((schema) => {
      const visibleRows = [schema];

      visibleRows.push(
        ...schema.subRows.filter((row) =>
          row.schemaName
            .toLowerCase()
            .includes(inputFilterValue.toLowerCase().trim()),
        ),
      );

      return visibleRows;
    });
  }, [data, displayedSchemasAndTables, inputFilterValue]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const [selectedTables, innerSetSelectedTables] = useState<Schema[]>([]);

  function setSelectedTables(tables: Schema[]) {
    innerSetSelectedTables(tables);
    props.setSelectedTables(tables);

    if (checkIfTablesHaveChanged) {
      checkIfTablesHaveChanged(tables);
    }
  }

  const virtualizer = useVirtualizer({
    count: filteredSchemasAndTables.length,
    getScrollElement: () => scrollRef.current,
    // Schemas are 45px tall and tables are 38px tall
    estimateSize: (index) =>
      filteredSchemasAndTables[index].parentName ? 38 : 45,
    // The number of items above and below the visible area to render.
    overscan: 2,
  });

  return (
    <div className="schemaTable-container">
      <SearchInput
        className="searchInput"
        value={inputFilterValue}
        setValue={onChangeFilter}
      />
      <div className="schemaTable-scrollContainer" ref={scrollRef}>
        <div
          className="schemaTable-virtualContainer"
          style={{
            // 1 pixel extra to prevent a weird scrollbar appearing when the children are exactly the same
            // height as the parent.
            height: `${virtualizer.getTotalSize() + 1}px`,
            position: "relative",
          }}
        >
          {virtualizer.getVirtualItems().map((virtualItem) => {
            const item = filteredSchemasAndTables[virtualItem.index];

            const isSchema = item.parentName == null;

            // Each item in the flat list is either a schema or a table.
            // We render different components based on the type of item.
            // See: https://tanstack.com/virtual/latest/docs/introduction

            if (isSchema) {
              return (
                <div
                  key={virtualItem.key}
                  className={classNames(
                    virtualItem.index === 0 ? "firstSchema" : null,
                  )}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  <CollapsableSchema
                    connectionName={selectedConnection.name}
                    connectionId={selectedConnection.id}
                    schema={item}
                    selectedTables={selectedTables}
                    setSchemas={setData}
                    cachedJobs={existingCacheJobs}
                  />
                </div>
              );
            } else {
              return (
                <div
                  key={virtualItem.key}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  <TableWithCheckbox
                    id={virtualItem.key.toString()}
                    table={item}
                    selectedTables={selectedTables}
                    setSelectedTables={setSelectedTables}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

type CollapsableSchemaProps = {
  connectionName: string;
  connectionId: string;
  schema: Schema;
  setSchemas: Dispatch<SetStateAction<Schema[]>>;
  selectedTables: Schema[];
  cachedJobs: string[] | null | undefined;
};

function CollapsableSchema(props: CollapsableSchemaProps) {
  const {
    connectionName,
    connectionId,
    schema,
    setSchemas,
    selectedTables,
    cachedJobs,
  } = props;

  const [customReportList, setCustomReportList] = useState<string[]>([]);

  const { data: tableList, isLoading } = useQuery({
    queryKey: ["/tables", connectionName, schema.schemaName],
    queryFn: () =>
      getTablesForSchema({
        connectionName,
        schema: schema.schemaName,
      }),
    enabled: schema.isExpanded,
    meta: {
      errorMessage:
        "An error occurred loading the tables for schema " + schema.schemaName,
    },
  });

  const { mutateAsync: getCustomReportListAsync, isPending } = useMutation({
    mutationKey: ["/customReportsList"],
    mutationFn: listCustomReports,
  });

  useEffect(() => {
    async function asyncWrapper() {
      const reportList = await getCustomReportListAsync(connectionId);
      const reportNameMap = reportList.customReports?.map(
        (report) => report.name!,
      );
      setCustomReportList(reportNameMap ?? []);
    }

    asyncWrapper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numSelectedTables = useMemo(() => {
    return selectedTables.filter((t) => t.parentName === schema.schemaName)
      .length;
  }, [schema.schemaName, selectedTables]);

  useEffect(() => {
    const mappedTables: Schema[] = (tableList ?? []).map((table) => {
      const fullName = `${connectionName}.${schema.schemaName}.${table.tableName}`;
      const isCustomReport = customReportList.includes(table.tableName);
      return {
        schemaName: table.tableName,
        parentName: schema.schemaName,
        isRowDisabled: cachedJobs?.includes(fullName) ?? false,
        isExpanded: false,
        subRows: [],
        assetType: AssetType.Data,
        dataAssetCategory: isCustomReport
          ? DataAssetCategory.CUSTOM_REPORT
          : DataAssetCategory[
              table.tableType as keyof typeof DataAssetCategory
            ],
      };
    });

    setSchemas((schemas) => {
      return schemas.map((s) => {
        if (s.schemaName === schema.schemaName) {
          return {
            ...s,
            subRows: mappedTables,
          };
        }
        return s;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableList]);

  function setExpanded(isExpanded: boolean) {
    setSchemas((schemas) => {
      return schemas.map((s) => {
        if (s.schemaName === schema.schemaName) {
          return { ...s, isExpanded };
        }
        return s;
      });
    });
  }

  return (
    <div className="collapsableSchema">
      <ButtonBase
        className="expandCollapseButton"
        onClick={() => setExpanded(!schema.isExpanded)}
        disableRipple
      >
        <span className="expandCollapseButton-text">
          {schema.isExpanded ? (
            <i className="collapseIcon fa fa-chevron-down"></i>
          ) : (
            <i className="collapseIcon fa fa-chevron-right"></i>
          )}

          <TextWithOverflowTooltip fullText={schema.schemaName} />
        </span>

        {numSelectedTables > 0 && (
          <CDChip
            color="secondary"
            className="tableCountBadge"
            label={`${numSelectedTables} Selected`}
          />
        )}
      </ButtonBase>

      {/* Show a backdrop over the entire page until the query finishes */}
      {(isLoading || isPending) && (
        <Portal>
          <Backdrop
            sx={{
              opacity: 0.15,
              zIndex: 999999,
            }}
            open
          >
            <CircularProgress />
          </Backdrop>
        </Portal>
      )}
    </div>
  );
}

type TableWithCheckboxProps = {
  id: string;
  table: Schema;
  selectedTables: Schema[];
  setSelectedTables: (tables: Schema[]) => void;
};

function TableWithCheckbox(props: TableWithCheckboxProps) {
  const { id, table, selectedTables, setSelectedTables } = props;

  const isSelected = selectedTables.some(
    (t) =>
      t.parentName === table.parentName && t.schemaName === table.schemaName,
  );

  function onSelectTable() {
    if (isSelected) {
      setSelectedTables(
        selectedTables.filter(
          (t) =>
            t.parentName !== table.parentName ||
            t.schemaName !== table.schemaName,
        ),
      );
    } else {
      setSelectedTables([...selectedTables, table]);
    }
  }

  return (
    <div className="tableWithCheckbox">
      <CDataTooltip
        title={
          table.isRowDisabled
            ? "You cannot create a job for a dataset that is already associated with another job."
            : null
        }
        type={CDataTooltipType.Dark}
      >
        <div>
          <CDataCheckbox
            checked={isSelected}
            onChange={() => onSelectTable()}
            disabled={table.isRowDisabled}
            id={id}
            aria-label={table.schemaName}
            inputProps={{
              "data-testid": `tableCheckbox-${table.schemaName.replace(/ /g, "_")}`,
            }}
          />
        </div>
      </CDataTooltip>
      <span className="asset-icon">
        <i
          className={classNames({
            "fa-regular fa-table":
              table.dataAssetCategory === DataAssetCategory.TABLE,
            "fa-light fa-border-none":
              table.dataAssetCategory === DataAssetCategory.VIEW,
            "fa-solid fa-file":
              table.dataAssetCategory === DataAssetCategory.CUSTOM_REPORT,
          })}
        />
      </span>
      <label htmlFor={id} className="tableLabel">
        <TextWithOverflowTooltip fullText={table.schemaName} />
      </label>
    </div>
  );
}
